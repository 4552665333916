import {Map} from 'immutable'


export const isAlive = owner => !owner.get('deceased')

export const ownerIdFromSeat = clientSeat => clientSeat.get('user-id') || clientSeat.get('invite-id')

export const clientType = clientSeat => {
  if (clientSeat.get('user-id'))
    return 'User'
  else if (clientSeat.get('invite-id'))
    return 'Invite'
}

export const ownerByIdAndType = ({owners, id, type}) => (
  owners.find(owner => (
    owner.get('id') === id && type === owner.get('owner-type')
  )) || Map()
)

export const ownerByClientSeat = ({owners, seat}) => (
  ownerByIdAndType({
    owners,
    id: ownerIdFromSeat(seat),
    type: clientType(seat)
  })
)

export const isActiveAndAlive = ({seat, owner}) => (
  seat.get('status') === 'Active' && isAlive(owner)
)

export const allOwnersActiveAndAlive = ({clientSeats, owners}) => (
  clientSeats.every(seat => (
    isActiveAndAlive({
      seat,
      owner: ownerByClientSeat({owners, seat})
    })
  ))
)

export const allOwnersActive = clientSeats => clientSeats.every(seat => seat.get('status') === 'Active')

export const activeActiveEverplan = ({clientSeats, isHousehold}) => isHousehold && allOwnersActive(clientSeats)

export const activeAndInactiveOrInactiveInactiveEverplan = ({clientSeats, isHousehold}) => (
  (isHousehold || clientSeats.size > 1) && !activeActiveEverplan({clientSeats, isHousehold})
)
